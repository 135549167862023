<style lang="less" scoped>
  .page__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
</style>

<template>
  <el-container class="page__wrapper">
    <div class="header">
      <el-row class="title">
        数据质量审阅项目工作流程
      </el-row>
      <el-row class="secondTitle">
        BM 系统每月10日为默认提交截止日期，如遇特殊情况需要调整月度上报时间，提交时间以捷豹路虎厂商正式通知为准。
      </el-row>
    </div>
    <div class="body">
      <template
        v-for="item in stepsData"
      >
        <div
          :key="'cd'+item.no"
          class="card"
          :style="{cursor: 'pointer'}"
          @click="handleIconClick(item)"
        >
          <p>{{ item.title }}</p>
          <p>{{ item.content }}</p>
          <img
            style="width:40%;"
            :src="require('../../assets/dashboard_icon' + item.no +(item.key===activeKey?'':'_gray')+ '.jpg')"
          >
        </div>
        <div
          v-if="item.no!==5"
          :key="'jt'+item.no"
          class="jointer"
        >
          ····
        </div>
      </template>
    </div>
    <div class="footer">
      <pre>
        月度工作流程提醒：

        发票数据确认及报表提交：10日下午17点（如遇节假日将邮件发送通知）
        异常指标解释/数据修改：报表提交后第二个工作日下午17点
        审阅确认函：数据修改后第六个工作日下午18点
      </pre>
    </div>
  </el-container>
</template>

<script>
const BASEURL = {
  dashboard: '/jlr/tt/homePage/index'
}
export default {
  data () {
    return {
      activeKey: '0',
      stepsData: [
        {
          no: 1,
          key: '1',
          title: '第一步',
          content: '发票数据确认',
          path: 'dealer_invoice'
        },
        {
          no: 2,
          key: '2',
          title: '第二步',
          content: '财务报表收集',
          path: 'dealer_finance'
        },
        {
          no: 3,
          key: '3',
          title: '第三步',
          content: '其他支持性文档上传',
          path: 'dealer_document'
        },
        {
          no: 4,
          key: '3', // 第三步和第四部同步，key 都是 '3'
          title: '第四步',
          content: '异常指标解释/数据修改',
          path: 'dealer_anomaly'
        },
        {
          no: 5,
          key: '5',
          title: '第五步',
          content: '审阅报表确认',
          path: 'dealer_reviewConfirm'
        }
      ]
    }
  },
  computed: {

  },
  mounted () {
    this.$axios.post(BASEURL.dashboard, { id: this.$store.state.app.userInfo.company.id }).then(resp => {
      const respData = resp.data
      if (respData) this.activeKey = respData
    })
  },
  methods: {
    handleIconClick (obj) {
      if (obj.key !== this.activeKey) return
      this.$router.push({ name: obj.path }).catch(_ => {})
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    .header {
      height: 120px;
      color: white;
      padding: 0 10px;
      background-image: url('../../assets/dashboard_bg.png');
      .title {
        font-size: 20px;
        height: 50%;
        line-height: 100px;
      }
      .secondTitle {
        font-size: 16px;
        height: 50%;
        line-height: 40px;
      }
    }
    .body {
      width: 100%;
      height: 100%;
      display: flex;
      padding: 30px 0;
      .card {
        width: 80%;
        height: 50%;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid #ebeef5;
        overflow: hidden;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        text-align: center;
      }
      .jointer {
        width: 20%;
        font-size: 26px;
        color: #ccc;
        font-weight: 800;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .footer {
      height: 160px;
      position: absolute;
      bottom: 10px;
      font-size: 14px;
    }
  }
</style>
