<template>
  <component :is="homeModule" />
</template>

<script>
import HomeAuth from './dashboard/Home.auth'
import HomeUnAuth from './dashboard/Home.unauth'

export default {
  name: 'Home',
  data () {
    return {
      // homeModule: null
    }
  },
  computed: {
    homeModule () {
      if (this.$store.state.app.isShowDashboard) {
        return HomeAuth
      } else {
        return HomeUnAuth
      }
    }
  }
}
</script>
