
<template>
  <div class="page__wrapper" />
</template>
<script>
export default {

}
</script>
<style lang="less" scoped>
  .page__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
</style>
